import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ASSETS from "../../../../../assets";


function DmsImageModal(props: $TSFixMe) {
    return (
        <Modal isOpen={props.modal}
            toggle={() => props.toggle()}
            className="dms-img-event-modal">
            <ModalHeader className={`mod ${props.theme}-bg`}>
                <div className="modlabel modheadcont mb-0">DMS Event</div>
                <button className={`mult ${props.theme}-bg`} onClick={() => props.toggle()}>
                    <img src={ASSETS.MULTIPLY} alt="multiply" />
                </button>
            </ModalHeader>
            <ModalBody>
                <div className={`position-relative ${props.theme}`}>
                    {props.imageUrl ? (
                        <img
                            src={props.imageUrl}
                            alt="DMS Image"
                            style={{
                                width: "100%",
                                height: "auto",
                                borderRadius: "8px",
                            }}
                        />
                    ) : (
                        <p>No image available</p>
                    )}
                </div>
            </ModalBody>
        </Modal>
    );
}

export default DmsImageModal;
