import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  endLoading,
  startLoading,
} from "../../../../../redux/reducers/general/general.actions";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../redux/store/store";
import { GetDmsEvent } from "../../../../../../global.types";
import DataTable from "../../../../Common/DataTable/dataTable";
import { ROUTES } from "../../../../../routes/routes";
import { Link } from "react-router-dom";
import PlayCircleOutlineSharpIcon from "@mui/icons-material/PlayCircleOutlineSharp";
import TimelapseSharpIcon from "@mui/icons-material/TimelapseSharp";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { getLocation } from "../../../../../api/axios.resources";
import { Popover, Tooltip } from "antd";
import { Spinner } from "reactstrap";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { LayoutContext } from "../../../../../context/LayoutContext";
import { convertTimestampToDate, encrypt } from "../../../../../services/common.functions.services";
import { DEVICE_VIDEO_STATUS } from "../../../../../constants";
import DmsImageModal from "./DmsImageModal";

interface dmsEventTableProps {
  setRecordsPerPage: (d: number) => () => void;
  setSelectedPage: (d: number) => () => void;
  setSortColumn: (d: string) => () => void;
  setIsAsc: (d: boolean) => () => void;
  selectedPage: number;
  recordsPerPage: number;
  isPageLoading: boolean;
  setIsPageLoading: (d: boolean) => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & dmsEventTableProps;

const DmsEventsTab = (props: Props) => {
  const { selectedTheme } = useContext(LayoutContext);
  const [dmsEventsList, setDmsEventsList] = useState<GetDmsEvent[]>([]);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [location, setLocation] = useState('');
  const [isModal, setIsModal] = useState(false);

  const toggle = () => setIsModal(!isModal);

  const HighEventsActionBtns4 = (data: $TSFixMe) => {
    const gyroCommand = data?.data?.command_info?.find((command: $TSFixMe) => command.commandType === "ACC");

    // Extract start and end timestamps if GYRO command is found
    const startTimestamp = gyroCommand?.startTimestamp;
    const endTimestamp = gyroCommand?.endTimestamp;

    const encryptedParams: $TSFixMe = encrypt({
      deviceId: data.data.deviceId,
      id: data.data._id,
      projectId: data.data.projectId,
      type: 'DMS_EVENT_VIDEO_INFO',
      endTimestamp: endTimestamp,
      startTimestamp: startTimestamp,
      timestamp: data.data.timestamp,
    });

    return (
      <div className="imptabbody">
        <Link to={`${ROUTES.HIGH_EVENTS_VIDEO}/${encryptedParams}`} className="tabLink">
          <PlayCircleOutlineSharpIcon className="dropico" />
        </Link>
      </div>
    );
  };

  const DmsImageActionBtns1 = (data: $TSFixMe) => {
    return (
      <div className="imptabbody">
        <PlayCircleOutlineSharpIcon onClick={toggle} className="dropico" />
        {isModal &&
          <DmsImageModal toggle={toggle} modal={isModal} theme={selectedTheme} imageUrl={data.data} />
        }
      </div>
    );
  };

  const getAddressFromLatLng = async (latitude: number, longitude: number) => {
    try {
      if (!latitude && !longitude) {
        setLocation("No address found");
        return;
      }
      const response = await getLocation(latitude, longitude);
      if (response) {
        setLocation(response.data.display_name);
      }
      if (response.data.error && !response.data.display_name) {
        setLocation(response.data.error);
      }
    } catch (error) {
      console.error('Error fetching address:', error);
      setLocation('Error fetching address');
    }
  };

  const dmsEventsColumns = [
    {
      title: "Action",
      format: (data: $TSFixMe) => {
        const videoCommand = data?.command_info?.find(
          (command: $TSFixMe) => command?.commandType === "VIDEO" && command?.channel === 1
        );

        const dmsImgCommand = data?.command_info?.find(
          (command: $TSFixMe) => command?.commandType === "DMSIMG"
        );

        if (videoCommand) {
          return (
            <>
              {videoCommand.status === DEVICE_VIDEO_STATUS.completed && videoCommand.responseCode === 0 && (
                <HighEventsActionBtns4 data={data} />
              )}
              {videoCommand.status === DEVICE_VIDEO_STATUS.completed && videoCommand.responseCode !== 0 && (
                <NotInterestedIcon className="nondropico" />
              )}
              {videoCommand.status === DEVICE_VIDEO_STATUS.pending && (
                <Tooltip title={"Already requested for video."} placement={"top"}>
                  <TimelapseSharpIcon className="nondropico" />
                </Tooltip>
              )}
            </>
          );
        }

        if (dmsImgCommand) {
          return (
            <>
              {dmsImgCommand.status === "COMPLETED" && (
                <DmsImageActionBtns1 data={data?.path} />
              )}
              {dmsImgCommand.status === "PENDING" && (
                <Tooltip title={"DMS image request is pending."} placement={"top"}>
                  <TimelapseSharpIcon className="nondropico" />
                </Tooltip>
              )}
            </>
          );
        }

        return <NotInterestedIcon className="nondropico" />;
      }
    },
    { title: "Vehicle Reg#", data: "vehicleRegNo", sorting: false, format: (data: $TSFixMe) => data.vehicleRegNo },
    { title: "Device ID", data: "deviceId" },
    { title: "Driver", data: "driverName", sorting: false, format: (data: $TSFixMe) => data.driverName },
    { title: " Organization", data: "organizationName", sorting: false, format: (data: $TSFixMe) => data.organizationName },
    {
      title: "Event",
      data: "eventName",
    },
    {
      title: "Location",
      format: (data: $TSFixMe) => (
        <Popover
          content={
            location ? (
              <>
                {location}
                {data?.latitude && data?.longitude &&
                  <div>
                    <a onClick={() => setSelectedEventId(null)} href={`https://www.google.com/maps/search/?api=1&query=${data.latitude},${data.longitude}`} rel="noreferrer" target="_blank">
                      View Location <OpenInNewIcon color="primary" className="wh-12-px" sx={{ fontSize: 12 }} /></a>
                  </div>
                }
              </>
            ) :
              <Spinner color="primary" size="sm" className="mx-auto d-block" />
          }
          trigger="click"
          placement="left"
          overlayClassName={`location-popover ${selectedTheme}`}
          visible={selectedEventId === data._id}
          onVisibleChange={(visible) => {
            if (visible) {
              getAddressFromLatLng(data.latitude, data.longitude);
            }
            setSelectedEventId(visible ? data._id : null);
          }}
        >
          <LocationOnOutlinedIcon className="dropico" />
        </Popover>
      ),
    },
    { title: "DateTime", data: "timestamp", format: (data: $TSFixMe) => data.timestamp ? convertTimestampToDate(props.user.timeZone.zoneId, data.timestamp, null, props.user.timeFormat) : "N/A", },
  ];

  useEffect(() => {
    const updatedDmsEventsList = (props.dmsEvents?.result?.data || []).map((item, index) => ({
      ...item,
      no: index + 1,
    }));
    setDmsEventsList(updatedDmsEventsList);
    return () => { };
  }, [props.dmsEvents]);

  return (
    <>
      <DataTable
        data={dmsEventsList}
        columns={dmsEventsColumns}
        bordered
        pageData={props.dmsEvents?.result?.page}
        totalRecrods={props.dmsEvents?.result?.totalRecrods}
        setPageSize={props.setRecordsPerPage}
        pageSize={props.recordsPerPage}
        setCurrentPage={props.setSelectedPage}
        currentPage={props.selectedPage}
        setSortColumn={props.setSortColumn}
        setIsAsc={props.setIsAsc}
        isPageLoading={props.isPageLoading}
        setIsPageLoading={props.setIsPageLoading}
        hover={true}
        responsive={true}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  dmsEvents: state.dataStore.dmsEvents,
  user: state.userSlice.user,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
});
export default connect(mapStateToProps, mapDispatchToProps)(DmsEventsTab);
