import {
  GetActiveSafetyEventsResp,
  GetCompletedResp,
  GetDeviceReportResp,
  GetDeviceStatusResp,
  GetDriverStatisticsResp,
  GetEngineerServiceCallsResp,
  GetEventReportResp,
  GetFirstConnectResp,
  GetGeoFenceEventsResp,
  GetHighEventsResp,
  GetDmsEventsResp,
  GetImportDevicesResp,
  GetLocationReportResp,
  GetLowEventsResp,
  GetOrganizationReportsResp,
  GetPanicButtonEventsResp,
  GetPendingDevicesResp,
  GetRequestedVideoResp,
  GetSaaSEventResp,
  GetTamperEventsResp,
  GetVehicleTripResp,
  GetVideoRequestReportsResp,
  VehicleTripsDetailsResp,
  VehicleTripsListResp,
} from "../../../../global.types";
import { AppActions } from "../../store/globalstore";
import {
  DELETE_IMPORT_DEVICES_SUCCESS,
  GET_ACTIVE_SAFETY_EVENTS_SUCCESS,
  GET_COMPLETED_SUCCESS,
  GET_DEVICE_REPORTS_SUCCESS,
  GET_DEVICE_STATUS_SUCCESS,
  GET_DRIVER_STATISTICS_SUCCESS,
  GET_ENGINEER_SERVICE_CALLS_SUCCESS,
  GET_EVENT_REPORTS_SUCCESS,
  GET_FIRST_CONNECTS_SUCCESS,
  GET_GEO_FENCE_EVENTS_SUCCESS,
  GET_HIGH_EVENTS_SUCCESS,
  GET_DMS_EVENTS_SUCCESS,
  GET_IMPORT_DEVICES_SUCCESS,
  GET_LOCATION_REPORTS_SUCCESS,
  GET_LOW_EVENTS_SUCCESS,
  GET_ORGANIZATION_REPORTS_SUCCESS,
  GET_PANIC_BUTTON_EVENTS_SUCCESS,
  GET_PENDING_DEVICES_SUCCESS,
  GET_REQUESTED_VIDEO_SUCCESS,
  GET_SA_AS_EVENTS_SUCCESS,
  GET_TAMPER_EVENTS_SUCCESS,
  GET_VEHICLE_TRIP_DETAIL_SUCCESS,
  GET_VEHICLE_TRIP_GPS_SUCCESS,
  GET_VEHICLE_TRIP_LIST_SUCCESS,
  GET_VEHICLE_TRIP_REPORTS_SUCCESS,
  GET_VEHICLE_TRIP_SUCCESS,
  GET_VIDEO_REQUEST_REPORTS_SUCCESS,
  RESET_DEVICE_REPORTS,
  RESET_EVENT_REPORTS,
  RESET_GEO_FENCE_EVENTS,
  RESET_HIGH_EVENTS,
  RESET_DMS_EVENTS,
  RESET_LOW_EVENTS,
  RESET_PANIC_BUTTON_EVENTS,
  RESET_REQUESTED_VIDEO,
  RESET_SA_AS_EVENTS,
  RESET_VEHICLE_TRIP,
  RESET_VEHICLE_TRIP_LIST,
  RESET_VIDEO_REQUEST_REPORTS,
} from "./data.types";

interface InitialState {
  saAsEvents: Partial<GetSaaSEventResp>;
  highEvents: Partial<GetHighEventsResp>;
  dmsEvents: Partial<GetDmsEventsResp>;
  deviceStatus: Partial<GetDeviceStatusResp>;
  geoFenceEvents: Partial<GetGeoFenceEventsResp>;
  tamperEvents: Partial<GetTamperEventsResp>;
  pendingDevices: Partial<GetPendingDevicesResp>;
  vehicleTrips: Partial<GetVehicleTripResp>;
  panicButtonEvents: Partial<GetPanicButtonEventsResp>;
  activeSafetyEvents: Partial<GetActiveSafetyEventsResp>;
  videoRequestReports: Partial<GetVideoRequestReportsResp>;
  requestedVideos: Partial<GetRequestedVideoResp>;
  firstConnects: Partial<GetFirstConnectResp>;
  lowEvents: Partial<GetLowEventsResp>;
  driverStatistics: Partial<GetDriverStatisticsResp>;
  completed: Partial<GetCompletedResp>;
  eventReports: Partial<GetEventReportResp>;
  organizationReports: Partial<GetOrganizationReportsResp>;
  engineerServiceCalls: Partial<GetEngineerServiceCallsResp>;
  importDevices: Partial<GetImportDevicesResp>;
  deviceReports: Partial<GetDeviceReportResp>;
  locationReports: Partial<GetLocationReportResp>;
  vehicleTripData: Partial<VehicleTripsListResp>;
  vehicleTripDetails: Partial<VehicleTripsDetailsResp>;
  vehicleTripGps: Partial<VehicleTripsDetailsResp>;
  chatData: { value: string; sent: string }[];
}

// initial State
const initialState: InitialState = {
  saAsEvents: {},
  highEvents: {},
  dmsEvents: {},
  deviceStatus: {},
  vehicleTrips: {},
  videoRequestReports: {},
  firstConnects: {},
  pendingDevices: {},
  geoFenceEvents: {},
  tamperEvents: {},
  panicButtonEvents: {},
  activeSafetyEvents: {},
  requestedVideos: {},
  deviceReports: {},
  locationReports: {},
  engineerServiceCalls: {},
  driverStatistics: {},
  lowEvents: {},
  eventReports: {},
  organizationReports: {},
  completed: {},
  importDevices: {},
  vehicleTripData: {},
  vehicleTripDetails: {},
  vehicleTripGps: {},
  chatData: [
    { value: "Test", sent: "user" },
    { value: "Demo", sent: "client" },
    { value: "Test", sent: "user" },
    { value: "Demo", sent: "client" },
  ],
};

export const dataReducer = (
  state: InitialState = initialState,
  action: AppActions
) => {
  switch (action.type) {
    case GET_SA_AS_EVENTS_SUCCESS:
      return {
        ...state,
        saAsEvents: action.payload,
      };

    case GET_DMS_EVENTS_SUCCESS:
      return {
        ...state,
        dmsEvents: action.payload,
      };
    case GET_DEVICE_STATUS_SUCCESS:
      return {
        ...state,
        deviceStatus: action.payload,
      };
    case GET_LOW_EVENTS_SUCCESS:
      return {
        ...state,
        lowEvents: action.payload,
      };
    case RESET_LOW_EVENTS:
      return {
        ...state,
        lowEvents: {},
      };
    case GET_REQUESTED_VIDEO_SUCCESS:
      return {
        ...state,
        requestedVideos: action.payload,
      };
    case RESET_REQUESTED_VIDEO:
      return {
        ...state,
        requestedVideos: {},
      };
    case GET_GEO_FENCE_EVENTS_SUCCESS:
      return {
        ...state,
        geoFenceEvents: action.payload,
      };
    case GET_TAMPER_EVENTS_SUCCESS:
      return {
        ...state,
        tamperEvents: action.payload,
      };
    case GET_PANIC_BUTTON_EVENTS_SUCCESS:
      return {
        ...state,
        panicButtonEvents: action.payload,
      };
    case GET_ACTIVE_SAFETY_EVENTS_SUCCESS:
      return {
        ...state,
        activeSafetyEvents: action.payload,
      };
    case GET_DRIVER_STATISTICS_SUCCESS:
      return {
        ...state,
        driverStatistics: action.payload,
      };
    case GET_VEHICLE_TRIP_SUCCESS:
      return {
        ...state,
        vehicleTrips: action.payload,
      };
    case RESET_VEHICLE_TRIP:
      return {
        ...state,
        vehicleTrips: {},
      };
    case GET_VIDEO_REQUEST_REPORTS_SUCCESS:
      return {
        ...state,
        videoRequestReports: action.payload,
      };
    case GET_VEHICLE_TRIP_REPORTS_SUCCESS:
      return {
        ...state,
        vehicleTriReports: action.payload,
      };
    case GET_ORGANIZATION_REPORTS_SUCCESS:
      return {
        ...state,
        organizationReports: action.payload,
      };
    case GET_DEVICE_REPORTS_SUCCESS:
      return {
        ...state,
        deviceReports: action.payload,
      };
    case GET_LOCATION_REPORTS_SUCCESS:
      return {
        ...state,
        locationReports: action.payload,
      };
    case GET_EVENT_REPORTS_SUCCESS:
      return {
        ...state,
        eventReports: action.payload,
      };
    case RESET_VIDEO_REQUEST_REPORTS:
      return {
        ...state,
        videoRequestReports: {},
      };
    case RESET_EVENT_REPORTS:
      return {
        ...state,
        eventReports: {},
      };
    case RESET_DEVICE_REPORTS:
      return {
        ...state,
        deviceReports: {},
      };
    case GET_FIRST_CONNECTS_SUCCESS:
      return {
        ...state,
        firstConnects: action.payload,
      };
    case GET_IMPORT_DEVICES_SUCCESS:
      return {
        ...state,
        importDevices: action.payload,
      };
    case DELETE_IMPORT_DEVICES_SUCCESS:
      return {
        ...state,
        importDevices: {
          ...state.importDevices,
          result: state.importDevices.result?.filter(
            (each) => each.id !== action.id
          ),
        },
      };
    case GET_ENGINEER_SERVICE_CALLS_SUCCESS:
      return {
        ...state,
        engineerServiceCalls: action.payload,
      };
    case GET_PENDING_DEVICES_SUCCESS:
      return {
        ...state,
        pendingDevices: action.payload,
      };
    case GET_COMPLETED_SUCCESS:
      return {
        ...state,
        completed: action.payload,
      };
    case RESET_VEHICLE_TRIP_LIST:
      return {
        ...state,
        vehicleTripData: {},
      };
    case RESET_GEO_FENCE_EVENTS:
      return {
        ...state,
        geoFenceEvents: {},
      };
    case RESET_DMS_EVENTS:
      return {
        ...state,
        dmsEvents: {},
      };
    case RESET_SA_AS_EVENTS:
      return {
        ...state,
        saAsEvents: {},
      };
    case RESET_PANIC_BUTTON_EVENTS:
      return {
        ...state,
        panicButtonEvents: {},
      };
    case GET_VEHICLE_TRIP_LIST_SUCCESS:
      return {
        ...state,
        vehicleTripData: action.payload,
      };
    case GET_VEHICLE_TRIP_DETAIL_SUCCESS:
      return {
        ...state,
        vehicleTripDetails: action.payload,
      };
    case GET_VEHICLE_TRIP_GPS_SUCCESS:
      return {
        ...state,
        vehicleTripGps: action.payload,
      };
    default:
      return state;
  }
};
