export const GET_SA_AS_EVENTS = "GET_SA_AS_EVENTS";
export const GET_SA_AS_EVENTS_SUCCESS = "GET_SA_AS_EVENTS_SUCCESS";
export const GET_SA_AS_EVENTS_ERROR = "GET_SA_AS_EVENTS_ERROR";

export const GET_HIGH_EVENTS = "GET_HIGH_EVENTS";
export const GET_HIGH_EVENTS_SUCCESS = "GET_HIGH_EVENTS_SUCCESS";
export const GET_HIGH_EVENTS_ERROR = "GET_HIGH_EVENTS_ERROR";
export const GET_CHAT_DATA = "GET_CHAT_DATA";

export const GET_DMS_EVENTS = "GET_DMS_EVENTS";
export const GET_DMS_EVENTS_SUCCESS = "GET_DMS_EVENTS_SUCCESS";
export const GET_DMS_EVENTS_ERROR = "GET_DMS_EVENTS_ERROR";

export const GET_LOW_EVENTS = "GET_LOW_EVENTS";
export const GET_LOW_EVENTS_SUCCESS = "GET_LOW_EVENTS_SUCCESS";
export const GET_LOW_EVENTS_ERROR = "GET_LOW_EVENTS_ERROR";

export const RESET_VEHICLE_TRIP_LIST = "RESET_VEHICLE_TRIP_LIST";

export const RESET_GEO_FENCE_EVENTS = "RESET_GEO_FENCE_EVENTS";

export const RESET_HIGH_EVENTS = "RESET_HIGH_EVENTS";

export const RESET_DMS_EVENTS = "RESET_DMS_EVENTS";

export const RESET_LOW_EVENTS = "RESET_LOW_EVENTS";

export const RESET_SA_AS_EVENTS = "RESET_SA_AS_EVENTS";

export const RESET_PANIC_BUTTON_EVENTS = "RESET_PANIC_BUTTON_EVENTS";

export const RESET_EVENT_REPORTS = "RESET_EVENT_REPORTS";

export const RESET_DEVICE_REPORTS = "RESET_DEVICE_REPORTS";

export const RESET_VIDEO_REQUEST_REPORTS = "RESET_VIDEO_REQUEST_REPORTS";

export const RESET_REQUESTED_VIDEO = "RESET_REQUESTED_VIDEO";

export const RESET_VEHICLE_TRIP = "RESET_VEHICLE_TRIP";

export const GET_VEHICLE_TRIP_LIST = "GET_VEHICLE_TRIP_LIST";
export const GET_VEHICLE_TRIP_LIST_SUCCESS = "GET_VEHICLE_TRIP_LIST_SUCCESS";
export const GET_VEHICLE_TRIP_LIST_ERROR = "GET_VEHICLE_TRIP_LIST_ERROR";

export const GET_REQUESTED_VIDEO = "GET_REQUESTED_VIDEO";
export const GET_REQUESTED_VIDEO_SUCCESS = "GET_REQUESTED_VIDEO_SUCCESS";
export const GET_REQUESTED_VIDEO_ERROR = "GET_REQUESTED_VIDEO_ERROR";

export const GET_GEO_FENCE_EVENTS = "GET_GEO_FENCE_EVENTS";
export const GET_GEO_FENCE_EVENTS_SUCCESS = "GET_GEO_FENCE_EVENTS_SUCCESS";
export const GET_GEO_FENCE_EVENTS_ERROR = "GET_GEO_FENCE_EVENTS_ERROR";

export const GET_TAMPER_EVENTS = "GET_TAMPER_EVENTS";
export const GET_TAMPER_EVENTS_SUCCESS = "GET_TAMPER_EVENTS_SUCCESS";
export const GET_TAMPER_EVENTS_ERROR = "GET_TAMPER_EVENTS_ERROR";

export const GET_PANIC_BUTTON_EVENTS = "GET_PANIC_BUTTON_EVENTS";
export const GET_PANIC_BUTTON_EVENTS_SUCCESS =
  "GET_PANIC_BUTTON_EVENTS_SUCCESS";
export const GET_PANIC_BUTTON_EVENTS_ERROR = "GET_PANIC_BUTTON_EVENTS_ERROR";

export const GET_ACTIVE_SAFETY_EVENTS = "GET_ACTIVE_SAFETY_EVENTS";
export const GET_ACTIVE_SAFETY_EVENTS_SUCCESS =
  "GET_ACTIVE_SAFETY_EVENTS_SUCCESS";
export const GET_ACTIVE_SAFETY_EVENTS_ERROR = "GET_ACTIVE_SAFETY_EVENTS_ERROR";

export const GET_DRIVER_STATISTICS = "GET_DRIVER_STATISTICS";
export const GET_DRIVER_STATISTICS_SUCCESS = "GET_DRIVER_STATISTICS_SUCCESS";
export const GET_DRIVER_STATISTICS_ERROR = "GET_DRIVER_STATISTICS_ERROR";

export const GET_VEHICLE_TRIP = "GET_VEHICLE_TRIP";
export const GET_VEHICLE_TRIP_SUCCESS = "GET_VEHICLE_TRIP_SUCCESS";
export const GET_VEHICLE_TRIP_ERROR = "GET_VEHICLE_TRIP_ERROR";

export const GET_VIDEO_REQUEST_REPORTS = "GET_VIDEO_REQUEST_REPORTS";
export const GET_VIDEO_REQUEST_REPORTS_SUCCESS =
  "GET_VIDEO_REQUEST_REPORTS_SUCCESS";
export const GET_VIDEO_REQUEST_REPORTS_ERROR =
  "GET_VIDEO_REQUEST_REPORTS_ERROR";

export const GET_VEHICLE_TRIP_REPORTS = "GET_VEHICLE_TRIP_REPORTS";
export const GET_VEHICLE_TRIP_REPORTS_SUCCESS =
  "GET_VEHICLE_TRIP_REPORTS_SUCCESS";
export const GET_VEHICLE_TRIP_REPORTS_ERROR = "GET_VEHICLE_TRIP_REPORTS_ERROR";

export const GET_ORGANIZATION_REPORTS = "GET_ORGANIZATION_REPORTS";
export const GET_ORGANIZATION_REPORTS_SUCCESS =
  "GET_ORGANIZATION_REPORTS_SUCCESS";
export const GET_ORGANIZATION_REPORTS_ERROR = "GET_ORGANIZATION_REPORTS_ERROR";

export const GET_DEVICE_REPORTS = "GET_DEVICE_REPORTS";
export const GET_DEVICE_REPORTS_SUCCESS = "GET_DEVICE_REPORTS_SUCCESS";
export const GET_DEVICE_REPORTS_ERROR = "GET_DEVICE_REPORTS_ERROR";

export const GET_LOCATION_REPORTS = "GET_LOCATION_REPORTS";
export const GET_LOCATION_REPORTS_SUCCESS = "GET_LOCATION_REPORTS_SUCCESS";
export const GET_LOCATION_REPORTS_ERROR = "GET_LOCATION_REPORTS_ERROR";

export const GET_EVENT_REPORTS = "GET_EVENT_REPORTS";
export const GET_EVENT_REPORTS_SUCCESS = "GET_EVENT_REPORTS_SUCCESS";
export const GET_EVENT_REPORTS_ERROR = "GET_EVENT_REPORTS_ERROR";

export const GET_FIRST_CONNECTS = "GET_FIRST_CONNECTS";
export const GET_FIRST_CONNECTS_SUCCESS = "GET_FIRST_CONNECTS_SUCCESS";
export const GET_FIRST_CONNECTS_ERROR = "GET_FIRST_CONNECTS_ERROR";

export const GET_IMPORT_DEVICES = "GET_IMPORT_DEVICES";
export const GET_IMPORT_DEVICES_SUCCESS = "GET_IMPORT_DEVICES_SUCCESS";
export const GET_IMPORT_DEVICES_ERROR = "GET_IMPORT_DEVICES_ERROR";

export const DELETE_IMPORT_DEVICES = "DELETE_IMPORT_DEVICES";
export const DELETE_IMPORT_DEVICES_SUCCESS = "DELETE_IMPORT_DEVICES_SUCCESS";
export const DELETE_IMPORT_DEVICES_ERROR = "DELETE_IMPORT_DEVICES_ERROR";

export const GET_ENGINEER_SERVICE_CALLS = "GET_ENGINEER_SERVICE_CALLS";
export const GET_ENGINEER_SERVICE_CALLS_SUCCESS =
  "GET_ENGINEER_SERVICE_CALLS_SUCCESS";
export const GET_ENGINEER_SERVICE_CALLS_ERROR =
  "GET_ENGINEER_SERVICE_CALLS_ERROR";

export const GET_PENDING_DEVICES = "GET_PENDING_DEVICES";
export const GET_PENDING_DEVICES_SUCCESS = "GET_PENDING_DEVICES_SUCCESS";
export const GET_PENDING_DEVICES_ERROR = "GET_PENDING_DEVICES_ERROR";

export const GET_COMPLETED = "GET_COMPLETED";
export const GET_COMPLETED_SUCCESS = "GET_COMPLETED_SUCCESS";
export const GET_COMPLETED_ERROR = "GET_COMPLETED_ERROR";

export const GET_DEVICE_STATUS = "GET_DEVICE_STATUS";
export const GET_DEVICE_STATUS_SUCCESS = "GET_DEVICE_STATUS_SUCCESS";
export const GET_DEVICE_STATUS_ERROR = "GET_DEVICE_STATUS_ERROR";

export const GET_VEHICLE_TRIP_DETAIL = "GET_VEHICLE_TRIP_DETAIL";
export const GET_VEHICLE_TRIP_DETAIL_SUCCESS = "GET_VEHICLE_TRIP_DETAIL_SUCCESS";
export const GET_VEHICLE_TRIP_DETAIL_ERROR = "GET_VEHICLE_TRIP_DETAIL_ERROR";

export const GET_VEHICLE_TRIP_GPS = "GET_VEHICLE_TRIP_GPS";
export const GET_VEHICLE_TRIP_GPS_SUCCESS = "GET_VEHICLE_TRIP_GPS_SUCCESS";
export const GET_VEHICLE_TRIP_GPS_ERROR = "GET_VEHICLE_TRIP_GPS_ERROR";