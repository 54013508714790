const routes = {
  DASHBOARD: "/dashboard",
  SA_AS_EVENT: "/sa-as-events",
  HIGH_EVENT: "/high-events",
  DMS_EVENT: "/dms-events",
  LOW_EVENT: "/low-events",
  REQUESTED_VIDEO: "/requested-video",
  GEO_FENCE_EVENT: "/geo-fence-events",
  TAMPER_EVENTS: "/tamper-events",
  PANIC_BUTTON_EVENT: "/panic-button-events",
  DRIVER_STATISTIC: "/driver-statistics",
  VEHICLE_TRIP: "/vehicle-trip",
  GEO_FENCING: "/geo-fencing",
  MAPS: "/maps",
  ACTIVE_SAFETY_EVENTS: "/active-safety-events",
  EVENTS_REPORT: "/event-report",
  LOCATION_REPORT: "/location-report",
  VIDEO_REQUEST_REPORT: "/video-request-report",
  DEVICE_REPORT: "/device-report",
  // ORGANIZATION_REPORT: "/organizationReport",
  VEHICLE_TRIP_REPORT: "/vehicle-trip-report",
  MANAGE_ROLES: "/manage-roles",
  USERS_MANAGEMENT: "/users-management",
  PROJECT_MANAGEMENT: "/project-management",
  SUPPORT_TICKET: "/support-ticket",
  DRIVER_MANAGEMENT: "/driver-management",
  DEVICE_MANAGEMENT: "/device-management",
  DEVICE_STATUS: "/device-status",
  ARCHIVE: "/archive",
  LOGOUT: "/logout",
  GLOBAL_SEARCH: "/global-search",
  USER_CONFIGURATION: "/user-configuration",
  HIGH_EVENTS_VIDEO: "/video-details",
  ADD_CONFIGURATION: "/add-configuration",
  EDIT_CONFIGURATION: "/edit-configuration",
  COMMON_CONFIGURATION: "/common-configuration",
  EDIT_DEVICE_CONFIG: "/common-edit-configuration",
  TRIPS_DETAIL: "/trip-details",
  DRIVER_SCORE_CARD: "/driver-score-card",
};

export const ROUTES = routes;
