import DevicesOtherOutlinedIcon from "@material-ui/icons/DevicesOtherOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from '@mui/icons-material/Close';
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { useContext, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import Select from "react-select";
import {
  alwaysOpenMenu,
  endLoading,
  startLoading,
} from "../../redux/reducers/general/general.actions";
import { ROUTES } from "../../routes/routes";
import DeviceStatus from "../Navbar/deviceStatus";
import Notifications from "../Navbar/notifications";
import RequestVideo from "../Navbar/requestVideo";
import User from "../Navbar/user";
import UserConfiguration from "../Navbar/user/userConfiguration";
import Dashboard from "../Sidebar/Dashboard";
import TripsDetail from "../Sidebar/Dashboard/trips/tabComponents";
import DriverStatistics from "../Sidebar/DriverStatistics";
import DriverScoreCard from "../Sidebar/DriverStatistics/tab/Components/DriverScoreCard";
import ALERTEvents from "../Sidebar/Events&Videos/Geo-FenceEvents";
import HighEvents from "../Sidebar/Events&Videos/HighEvents";
import DmsEvents from "../Sidebar/Events&Videos/DmsEvents";
import LowEvents from "../Sidebar/Events&Videos/LowEvents";
import PanicEvents from "../Sidebar/Events&Videos/PanicButtonEvents";
import RequestedVideo from "../Sidebar/Events&Videos/RequestedVideo";
import ActiveSafety from "../Sidebar/Events&Videos/SA-ASEvents";
import DeviceManagement from "../Sidebar/Management&Settings/DeviceManagement";
import DriverManagement from "../Sidebar/Management&Settings/DriverManagement";
import ManageRoles from "../Sidebar/Management&Settings/ManageRoles";
import ProjectManagement from "../Sidebar/Management&Settings/ProjectManagement";
import EditDeviceConfiguration from "../Sidebar/Management&Settings/ProjectManagement/Setting/commonConfig/EditDeviceConfiguration";
import CommonConfiguration from "../Sidebar/Management&Settings/ProjectManagement/Setting/commonConfig/manageTemplate";
import AddConfiguration from "../Sidebar/Management&Settings/ProjectManagement/Setting/commonConfig/manageTemplate/addConfiguration";
import SupportTicket from "../Sidebar/Management&Settings/SupportTicket";
import UsersManagement from "../Sidebar/Management&Settings/UsersManagement";
import Map from "../Sidebar/MapComponents";
import DeviceReport from "../Sidebar/Report/DeviceReport";
import EventReport from "../Sidebar/Report/EventReport";
import VehicleTripReport from "../Sidebar/Report/VehicleTripReport";
import VideoRequestReport from "../Sidebar/Report/VideoRequestReport";
import GeoFencing from "../Sidebar/Trips&Geo-fence/Geo Fencing";
import VehicleTrip from "../Sidebar/Trips&Geo-fence/Vehicle Trip";
import Sidebar from "./Sidebar";
//@ts-expect-error
import Fade from "react-reveal/Fade";
import { RootState } from "../../redux/reducers/rootReducer";
import { AppDispatch } from "../../redux/store/store";
import Archive from "../Sidebar/Archive/Archive";
import HighEventsVideo from "../Sidebar/Events&Videos/SA-ASEvents/tab/Components/HighEventsVideo";
import CopyrightFooter from "../Common/CopyrightFooter";
import { LayoutContext } from "../../context/LayoutContext";
import { MAP, THEME_TYPE } from "../../constants";
import usePermission from "../../constants/usePermission";
import PageNotFound from "../Common/ErrorComponents/error404";
import LocationReport from "../Sidebar/Report/LocationReport";
import TamperEvents from "../Sidebar/Events&Videos/TamperEvents";
import ActiveSafetyEvents from "../Sidebar/Events&Videos/ActiveSafetyEvents";
import { Tooltip } from "antd";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function Side(props: Props) {
  const [alwaysOpen, setAlwaysOpen] = useState(true);
  const [hover, setHover] = useState(false);
  const { selectedTheme, selectedProject, setSelectedProject }: $TSFixMe =
    useContext(LayoutContext);
  //Permission variables
  const isDeviceStatusReadWrite = usePermission([
    "DEVICE_STATUS_READ",
    "DEVICE_STATUS_WRITE",
  ]);
  const isVehicleTripReadWrite = usePermission([
    "VEHICLE_TRIP_WRITE",
    "VEHICLE_TRIP_READ",
  ]);
  const isGeoFencingReadWrite = usePermission([
    "GEOFENCING_READ",
    "GEOFENCING_WRITE",
  ]);
  const projectReadWrite = usePermission([
    "PROJECT_MANAGEMENT_READ",
    "PROJECT_MANAGEMENT_WRITE",
  ]);
  const supportTicketsReadWrite = usePermission([
    "SUPPORT_TICKETS_READ",
    "SUPPORT_TICKETS_WRITE",
  ]);
  const activeSafetyEventsReadWrite = usePermission(["ACTIVE_SAFATY_EVENT_READ", "ACTIVE_SAFATY_EVENT_WRITE"]);
  const userReadWrite = usePermission([
    "USERS_MANAGEMENT_READ",
    "USERS_MANAGEMENT_WRITE",
  ]);
  const deviceReadWrite = usePermission([
    "DEVICE_MANAGEMENT_READ",
    "DEVICE_MANAGEMENT_WRITE",
  ]);
  const deviceStatusReadWrite = usePermission([
    "DEVICE_STATUS_READ",
    "DEVICE_STATUS_WRITE",
  ]);
  const driverReadWrite = usePermission([
    "DRIVER_MANAGEMENT_READ",
    "DRIVER_MANAGEMENT_WRITE",
  ]);
  const rolseReadWrite = usePermission([
    "ROLES_MANAGEMENT_READ",
    "ROLES_MANAGEMENT_WRITE",
  ]);
  const mapReadWrite = usePermission(MAP);
  const eventReportReadWrite = usePermission([
    "EVENT_REPORT_READ",
    "EVENT_REPORT_WRITE",
  ]);
  const videoReportReadWrite = usePermission([
    "VIDEO_REQUEST_REPORT_READ",
    "VIDEO_REQUEST_REPORT_WRITE",
  ]);
  const deviceReportReadWrite = usePermission([
    "DEVICE_REPORT_READ",
    "DEVICE_REPORT_WRITE",
  ]);
  const locationReportReadWrite = usePermission([
    "LOCATION_REPORT_READ",
    "LOCATION_REPORT_WRITE",
  ]);
  const vehicleReportReadWrite = usePermission([
    "VEHICLETRIP_REPORT_READ",
    "VEHICLETRIP_REPORT_WRITE",
  ]);
  const driverReportReadWrite = usePermission([
    "DRIVER_STATISTICS_READ",
    "DRIVER_STATISTICS_WRITE",
  ]);
  // const saasEventReadWrite = usePermission([
  //   "HIGH_EVENTS_READ",
  //   "HIGH_EVENTS_WRITE",
  //   "LOW_EVENTS_READ",
  //   "LOW_EVENTS_WRITE",
  // ]);
  const highEventReadWrite = usePermission([
    "HIGH_EVENTS_READ",
    "HIGH_EVENTS_WRITE",
  ]);

  const dmsEventReadWrite = usePermission([
    "DMS_EVENT_READ",
    "DMS_EVENT_WRITE",
  ]);
  const lowEventReadWrite = usePermission([
    "LOW_EVENTS_READ",
    "LOW_EVENTS_WRITE",
  ]);
  const requestVideoReadWrite = usePermission([
    "REQUEST_VIDEO_READ",
    "REQUEST_VIDEO_WRITE",
  ]);
  const geoEventReadWrite = usePermission([
    "GEOFENCING_EVENT_READ",
    "GEOFENCING_EVENT_WRITE",
  ]);
  const tamperEventsReadWrite = usePermission([
    "TAMPER_EVENTS_READ",
    "TAMPER_EVENTS_WRITE",
  ]);
  const panicBtnReadWrite = usePermission([
    "PANIC_BUTTON_VIDEO_READ",
    "PANIC_BUTTON_VIDEO_WRITE",
  ]);


  const handleHover = () => {
    !alwaysOpen && props.openMenu(true);
    !alwaysOpen && setHover(true);
  };
  const handleMouseLeave = () => {
    !alwaysOpen && props.openMenu(false);
    !alwaysOpen && setHover(false);
  };

  return (
    <Router>
      <div className={`App1 ${selectedTheme}`}>
        <div
          className={`${"Sidebar " + (alwaysOpen ? "toggle" : "hide")} ${(selectedTheme === THEME_TYPE.GREY_SCALE ||
            selectedTheme === THEME_TYPE.BLUE_WHITE_GREY) &&
            "sidebar-light-shadow"
            } ${selectedTheme}`}
          onMouseEnter={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          <Scrollbars autoHide>
            <Sidebar
              handleDrawerOpen={alwaysOpen}
              hover={hover}
              alwaysOpen={alwaysOpen}
            />
          </Scrollbars>
        </div>
        <div className={"Content " + (alwaysOpen || hover ? "shrink" : "")}>
          <div
            className={`header ${selectedTheme}-bg ${alwaysOpen || hover
              ? "header-sticky-toggle-on"
              : "header-sticky-toggle-off"
              }`}
          >
            <div className={`head ${selectedTheme}-bg`}>
              <div
                onClick={() => {
                  setAlwaysOpen(!alwaysOpen);
                  props.openMenu(!props.isOpen);
                }}
                className="header-icons-link">
                {alwaysOpen ? (
                  <MenuIcon className="header-icons" />
                ) : (
                  <CloseIcon className="header-icons" />
                )}
              </div>

              <div className="HeaderRight">
                <div className="HeaderRightfield">
                  <Fade left duration={1000}>
                    <Select
                      className="headDrop"
                      isSearchable={false}
                      placeholder="Project"
                      options={props.dropdowns.projectListDD?.result ?? []}
                      getOptionLabel={(option: $TSFixMe) => option.name}
                      getOptionValue={(option: $TSFixMe) => option.id.toString()}
                      classNamePrefix={`custom-select-style_${selectedTheme}`}
                      value={selectedProject ? {
                        id: selectedProject.id,
                        name: selectedProject.name
                      } : null}
                      maxMenuHeight={180}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base: $TSFixMe) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      onChange={async (option) => {
                        props.startLoading();
                        await setSelectedProject(option);
                        localStorage.setItem("selectedProjectData", JSON.stringify(option));
                        props.endLoading();
                      }}
                    />
                  </Fade>
                </div>

                <Fade right duration={1000}>
                  <RequestVideo />
                  <Link to={ROUTES.DEVICE_STATUS} className="header-icons-link">
                    {isDeviceStatusReadWrite && (
                      <Tooltip placement="bottom" title={"Device Status"}
                        align={{
                          points: ['tc', 'bc'], // Align tooltip top-center to button bottom-center
                          offset: [0, -5], // Adjust the offset (x, y)
                          targetOffset: [0, 0], // Adjust the target offset
                        }}
                        overlayInnerStyle={{ fontSize: '11px', padding: '3px 8px', minHeight: '22px' }}>
                        <DevicesOtherOutlinedIcon className="header-icons" />
                      </Tooltip>
                    )}
                  </Link>

                  {/* <Link to={ROUTES.DEVICE_STATUS} className="tabLink">
                    {isDeviceStatusReadWrite && (
                      <button className="navhov notify">
                        <Row>
                          <Col md={4} className="navhovCol">
                            <DevicesOtherOutlinedIcon className="headicon" />
                          </Col>
                          <Col md={8} className="navhovCol2">
                            <Row>Device </Row>
                            <Row>Status </Row>
                          </Col>
                        </Row>
                      </button>
                    )}
                  </Link> */}
                  <Link to={ROUTES.COMMON_CONFIGURATION} className="header-icons-link">
                    {props.user.vendorAdmin && (
                      <Tooltip placement="bottom" title={"Common Configuration"}
                        align={{
                          points: ['tc', 'bc'], // Align tooltip top-center to button bottom-center
                          offset: [0, -5], // Adjust the offset (x, y)
                          targetOffset: [0, 0], // Adjust the target offset
                        }}
                        overlayInnerStyle={{ fontSize: '11px', padding: '3px 8px', minHeight: '22px' }}>
                        <SettingsOutlinedIcon className="header-icons" />
                      </Tooltip>
                    )}
                  </Link>
                  {/* <Link to={ROUTES.COMMON_CONFIGURATION} className="tabLink">
                    {props.user.vendorAdmin && (
                      <button className="navhov notify">
                        <Row>
                          <Col md={3} className="navhovCol">
                            <SettingsOutlinedIcon className="headicon" />
                          </Col>
                          <Col md={9} className="navhovCol2">
                            <Row>Common </Row>
                            <Row>Configuration </Row>
                          </Col>
                        </Row>
                      </button>
                    )}
                  </Link> */}
                  <div className="nofify1">
                    <Notifications user={props.user} />
                  </div>
                  <User />
                </Fade>
              </div>
            </div>
          </div>
          <div className="main">
            <div
              className={`mainContent ${(selectedTheme === THEME_TYPE.GREY_SCALE ||
                selectedTheme === THEME_TYPE.BLUE_WHITE_GREY) &&
                "sidebar-light"
                }`}
            >
              <Switch>
                <Route exact path="/dashboard">
                  <Dashboard />
                </Route>
                {/* {saasEventReadWrite &&
                  <Route path="/sa-as-events">
                    <ActiveSafety />
                  </Route>
                } */}
                {dmsEventReadWrite && (
                  <Route path="/dms-events">
                    <DmsEvents />
                  </Route>
                )} 
                {highEventReadWrite && (
                  <Route path="/high-events">
                    <HighEvents />
                  </Route>
                )}
                {lowEventReadWrite && (
                  <Route path="/low-events">
                    <LowEvents />
                  </Route>
                )}
                {requestVideoReadWrite && (
                  <Route path="/requested-video">
                    <RequestedVideo />
                  </Route>
                )}
                {geoEventReadWrite && (
                  <Route path="/geo-fence-events">
                    <ALERTEvents />
                  </Route>
                )}
                {tamperEventsReadWrite && (
                  <Route path="/tamper-events">
                    <TamperEvents />
                  </Route>
                )}
                {panicBtnReadWrite && (
                  <Route path="/panic-button-events">
                    <PanicEvents />
                  </Route>
                )}
                {activeSafetyEventsReadWrite &&
                  <Route path="/active-safety-events">
                    <ActiveSafetyEvents />
                  </Route>
                }
                {driverReportReadWrite && (
                  <Route path="/driver-statistics">
                    <DriverStatistics />
                  </Route>
                )}
                {driverReportReadWrite &&
                  <Route path="/driver-score-card/:encryptedParams(.*)">
                    <DriverScoreCard />
                  </Route>
                }
                {isVehicleTripReadWrite && (
                  <Route path="/vehicle-trip">
                    <VehicleTrip />
                  </Route>
                )}
                {isGeoFencingReadWrite && (
                  <Route path="/geo-fencing">
                    <GeoFencing />
                  </Route>
                )}
                {mapReadWrite && (
                  <Route path="/maps">
                    <Map />
                  </Route>
                )}
                {eventReportReadWrite && (
                  <Route path="/event-report">
                    <EventReport />
                  </Route>
                )}
                {videoReportReadWrite && (
                  <Route path="/video-request-report">
                    <VideoRequestReport />
                  </Route>
                )}
                {deviceReportReadWrite && (
                  <Route path="/device-report">
                    <DeviceReport />
                  </Route>
                )}
                {locationReportReadWrite &&
                  <Route path="/location-report">
                    <LocationReport />
                  </Route>
                }
                {vehicleReportReadWrite && (
                  <Route path="/vehicle-trip-report">
                    <VehicleTripReport />
                  </Route>
                )}
                {rolseReadWrite && (
                  <Route path="/manage-roles">
                    <ManageRoles />
                  </Route>
                )}
                {userReadWrite && (
                  <Route path="/users-management">
                    <UsersManagement />
                  </Route>
                )}
                {projectReadWrite && (
                  <Route path="/project-management">
                    <ProjectManagement />
                  </Route>
                )}
                {supportTicketsReadWrite &&
                  <Route path="/support-ticket">
                    <SupportTicket />
                  </Route>
                }
                {driverReadWrite && (
                  <Route path="/driver-management">
                    <DriverManagement />
                  </Route>
                )}
                {deviceReadWrite && (
                  <Route path="/device-management">
                    <DeviceManagement />
                  </Route>
                )}
                {deviceStatusReadWrite &&
                  <Route path="/device-status">
                    <DeviceStatus />
                  </Route>
                }
                {props.user.vendorAdmin && (
                  <Route path="/common-configuration">
                    <CommonConfiguration />
                  </Route>
                )}
                {props.user.vendorAdmin &&
                  <Route path="/add-configuration">
                    <AddConfiguration />
                  </Route>
                }
                {props.user.vendorAdmin &&
                  <Route path="/common-edit-configuration">
                    <EditDeviceConfiguration />
                  </Route>
                }
                <Route path="/archive">
                  <Archive />
                </Route>
                <Route path="/logout">
                  <Dashboard />
                </Route>
                <Route path="/user-configuration">
                  <UserConfiguration />
                </Route>
                <Route path="/video-details/:encryptedParams(.*)">
                  <HighEventsVideo />
                </Route>
                <Route path="/trip-details/:encryptedParams(.*)">
                  <TripsDetail />
                </Route>
                <Route path="*">
                  <PageNotFound />
                </Route>
              </Switch>
            </div>
            <div
              className={`footer-section ${(selectedTheme === THEME_TYPE.GREY_SCALE ||
                selectedTheme === THEME_TYPE.BLUE_WHITE_GREY) &&
                "sidebar-light"
                }`}
            >
              <CopyrightFooter />
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.generalSlice.isLoading,
  user: state.userSlice.user,
  isOpen: state.generalSlice.open,
  dropdowns: state.dropdownList.dropdowns,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  openMenu: (isOpen: $TSFixMe) => dispatch(alwaysOpenMenu(isOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Side);
